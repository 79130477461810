import { ThemeOptions } from "@mui/material";
import { colors, secondary } from "../colors";
import { typography } from "../typography";

export const base: ThemeOptions = {
  ...colors,
  ...typography,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: secondary,
          textDecorationColor: secondary,
        },
      },
    },
  },
};
