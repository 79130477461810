import { ThemeOptions } from "@mui/material";

export const primaryFonts = [
  "Atkinson Hyperlegible",
  "sans-serif",
];

export const secondaryFonts = [
  "Courier Prime",
  "monospace",
];

export const primaryFontFamily = primaryFonts
  .map((font) => font.includes(" ") ? `"${font}"` : font)
  .join(", ");

export const secondaryFontFamily = secondaryFonts
  .map((font) => font.includes(" ") ? `"${font}"` : font)
  .join(", ");

export const typography: ThemeOptions = {
  typography: {
    fontFamily: primaryFontFamily,
    subtitle2: {
      fontFamily: secondaryFontFamily,
    },
    body2: {
      fontFamily: secondaryFontFamily,
    },
    overline: {
      fontFamily: secondaryFontFamily,
    },
  },
};
