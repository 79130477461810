import { CssBaseline, ThemeProvider } from "@mui/material";
import Router from "./Router";
import { blackTheme } from "./styles/themes/black";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={blackTheme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
