import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { cyan, deepOrange, teal, yellow } from "@mui/material/colors";
import Page from "../../widgets/Page/Page";

const colours = [
  // Communicator
  teal.A400,
  // Conductor
  deepOrange.A700,
  // Scratchpad
  yellow.A100,
  // Workstation Software
  cyan[900],
];

const Index: React.FC = () => (
  <Page>
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper sx={{ overflow: "hidden" }}>
        <Stack>
          <Typography
            variant="h1"
            sx={{
              p: 3,
              textAlign: "center",
            }}
          >
            Workstation Software
          </Typography>
          <Grid container>
            {colours.map(colour => (
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: 2,
                    backgroundColor: colour,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  </Page>
);

export default Index;
