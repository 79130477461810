import { Container } from "@mui/material";

export type PageProps = React.PropsWithChildren;

const Page: React.FC<PageProps> = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default Page;
