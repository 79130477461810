import { createTheme, responsiveFontSizes } from "@mui/material";
import { grey } from "@mui/material/colors";
import { deepmerge } from "@mui/utils";
import { black } from "../colors";
import { base } from "./base";

export const blackTheme = responsiveFontSizes(createTheme(deepmerge(base, {
  palette: {
    mode: "dark",
    background: {
      default: black,
      paper: "#141414",
    },
    text: {
      primary: grey[400],
      secondary: grey[500],
    },
    divider: grey[800],
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: grey[200],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: grey[50],
          },
        }
      },
    },
  },
})));
